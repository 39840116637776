import { ACCESS_TOKEN, USERNAME, API, STYLE_IDS } from './config'
import * as URLUtils from '@avcan/utils/url'

export function createStyleUrl({
    styleId = STYLE_IDS.default,
    overlay,
    longitude = -118.2, // ~Revelstoke
    latitude = 50, // ~Revelstoke
    zoom = 10,
    bearing = 0,
    pitch = 0,
    width = 250,
    height = 250,
    retina = false,
} = {}) {
    const path = URLUtils.buildPath(
        API,
        'styles',
        'v1',
        USERNAME,
        styleId,
        'static',
        Array.isArray(overlay) ? overlay.join(',') : overlay,
        [longitude, latitude, zoom, bearing, pitch].join(','),
        `${width}x${height}${retina ? '@2x' : ''}`
    )
    const params = {
        access_token: ACCESS_TOKEN,
    }

    return URLUtils.appendParams(path, params)
}
