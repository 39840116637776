import { useIntl } from 'react-intl'
import StaticMap from 'components/map/StaticMap'
import { useReport } from './Context'
import * as Mapbox from 'utils/mapbox'
import * as MapContext from 'contexts/map'
import * as Env from 'env'
import { INCIDENT } from '@avcan/constants/products/min/types'

import styles from './MountainInformationNetwork.module.css'

export default function ContextMap() {
    const report = useReport()
    const { title, location, observations } = report
    const { longitude, latitude } = location
    const filename = INCIDENT in observations ? 'min-pin-with-incident' : 'min-pin'
    const src = 'https://assets.avalanche.ca/images/' + filename + '.png'
    const props = { center: [longitude, latitude], src, title, zoom: 8, height: 200 }
    let Map = DynamicMap

    if (Env.server || !Mapbox.supported()) {
        Map = FallbackMap
    }

    return (
        <div className={styles.ContextMap}>
            <Map {...props} />
        </div>
    )
}

function FallbackMap({ center, zoom, src, title, height }) {
    const [longitude, latitude] = center
    const overlay = `url-${encodeURIComponent(src)}(${longitude},${latitude})`

    return (
        <StaticMap
            zoom={zoom}
            longitude={longitude}
            latitude={latitude}
            height={height}
            overlay={overlay}
            title={title}
        />
    )
}

function DynamicMap({ center, zoom, src, title }) {
    const intl = useIntl()
    const alt = intl.formatMessage({
        defaultMessage: 'Mountain Information Network report pin',
        description: 'Mountain Information Network Context Map',
    })

    return (
        <MapContext.Map options={{ center, zoom }}>
            <MapContext.FullscreenControl />
            <MapContext.NavigationControl />
            <MapContext.Marker lnglat={center}>
                <img src={src} title={title} alt={alt} width={20} />
            </MapContext.Marker>
        </MapContext.Map>
    )
}
