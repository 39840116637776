import * as React from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { useIntl } from 'react-intl'
import { createStyleUrl } from 'services/mapbox/api'
import { useEventListener } from 'hooks'
import { noop } from '@avcan/utils/function'

StaticMap.propTypes = {
    title: PropTypes.string,
    tracked: PropTypes.bool,
    styleId: PropTypes.string,
    overlay: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    zoom: PropTypes.number,
    bearing: PropTypes.number,
    pitch: PropTypes.number,
    auto: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    retina: PropTypes.bool,
}

export default function StaticMap(props) {
    const {
        tracked,
        title,
        styleId,
        overlay,
        longitude,
        latitude,
        zoom,
        bearing,
        pitch,
        auto,
        height,
        retina = true,
    } = props
    const ref = React.useRef(null)
    const width = ref.current?.clientWidth || 1026
    const [url, setUrl] = React.useState(createStyleUrl({ ...props, width }))
    const intl = useIntl()
    const alt = intl.formatMessage(
        {
            description: 'alt for static map generated by mapbox API',
            defaultMessage: 'Map for Mountain Information Network report: {title}',
        },
        { title }
    )
    const resizeHandler = React.useCallback(() => {
        const options = {
            styleId,
            overlay,
            longitude,
            latitude,
            zoom,
            bearing,
            pitch,
            auto,
            height,
            retina,
            width,
        }

        setUrl(createStyleUrl(options))
    }, [styleId, overlay, longitude, latitude, zoom, bearing, pitch, auto, height, retina, width])

    React.useEffect(() => {
        if (tracked) {
            resizeHandler()
        }
    }, [tracked, resizeHandler])
    useEventListener('resize', tracked ? debounce(resizeHandler, 500) : noop)

    return (
        <div ref={ref}>
            <img src={url} alt={alt} title={title} />
        </div>
    )
}
