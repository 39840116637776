import Link from 'next/link'
import { FormattedMessage } from 'react-intl'
import { Sidebar, Contact, Follow, Share, Item } from 'components/sidebar'
import { FORECASTERS } from 'constants/emails'
import * as utils from 'utils/min'

export default function MountainInformationNetworkSidebar({ children }) {
    return (
        <Sidebar>
            {children}
            <Item>
                <SidebarLink href="/weather">
                    <FormattedMessage
                        description="Layout products/min/Sidebar"
                        defaultMessage="Your daily Mountain Weather Forecast"
                    />
                </SidebarLink>
            </Item>
            <Item>
                <SidebarLink href={utils.path('submit')}>
                    <FormattedMessage
                        description="Layout products/min/Sidebar"
                        defaultMessage="Submit a Mountain Information Report"
                    />
                </SidebarLink>
            </Item>
            <Item>
                <SidebarLink href={utils.submission()}>
                    <FormattedMessage
                        description="Layout products/min/Sidebar"
                        defaultMessage="Visit all submitted reports"
                    />
                </SidebarLink>
            </Item>
            <Item>
                <SidebarLink href="/blogs">
                    <FormattedMessage
                        description="Layout products/min/Sidebar"
                        defaultMessage="Visit our Blog"
                    />
                </SidebarLink>
            </Item>
            <Follow />
            <Share />
            <Contact email={FORECASTERS} />
        </Sidebar>
    )
}

// Utils
function SidebarLink({ href, children }) {
    return <Link href={href}>{children}</Link>
}
