import PropTypes from 'prop-types'
import * as Components from 'components/tag'

Tags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    options: PropTypes.object.isRequired,
}

export default function Tags({ value, options }) {
    if (value.length === 0) {
        return null
    }

    return (
        <Components.TagSet>
            {value.map(tag => (
                <Components.Tag key={tag}>{options[tag]}</Components.Tag>
            ))}
        </Components.TagSet>
    )
}
