import { useReport } from './Context'
import PropTypes from 'prop-types'

Header.propTypes = {
    as: PropTypes.string,
}

export default function Header({ as: As = 'h2' }) {
    const { title } = useReport()

    return <As>{title}</As>
}
