import * as URLUtils from '@avcan/utils/url'

// TODO Look of we could use a bit more these util functions

export function shareUrl(id) {
    return document.location.origin + submission(id)
}

export function path(...paths) {
    return URLUtils.buildPath('/mountain-information-network', paths)
}

export function submission(id) {
    return path('submissions', id)
}
