import { get, has } from 'lodash'
import * as Context from './Context'
import * as Tabs from 'components/tabs'
import Observations from './Observations'
import { ObservationColors, ObservationTypes } from '@avcan/constants/products/min'
import { INCIDENT } from '@avcan/constants/products/min/types'
import { indexOf } from '@avcan/utils/products/min/types'
import * as StringUtils from '@avcan/utils/string'

export default function ObservationsSet() {
    const display = Context.useSchema()
    const report = Context.useReport()
    const { path, content } = display.content.find(item => item.widget === 'observations-set')
    const data = get(report, path)

    return (
        <Tabs.Container key={report.id} activeTab={getActiveTab(data)}>
            <Tabs.HeaderSet>
                {content.map(({ path, header }) => {
                    const [type] = StringUtils.toPath(path).reverse()

                    return (
                        <Tabs.ColoredHeader
                            key={path}
                            arrow
                            disabled={!has(report, path)}
                            color={ObservationColors.get(type)}>
                            {header}
                        </Tabs.ColoredHeader>
                    )
                })}
            </Tabs.HeaderSet>
            <Tabs.PanelSet>
                {content.map(item => (
                    <Tabs.Panel key={item.path}>
                        <Observations {...item} />
                    </Tabs.Panel>
                ))}
            </Tabs.PanelSet>
        </Tabs.Container>
    )
}

// Constants and utils
function getActiveTab(observations) {
    if (INCIDENT in observations) {
        return indexOf(INCIDENT)
    }

    const types = Array.from(ObservationTypes)
    const type = types.find(type => type in observations)

    return indexOf(type)
}
